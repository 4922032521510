import React from 'react';
import { StrapiCareersSection1 } from '@/api/careers';
import { Box, Container } from '@mui/material';
import { MarkdownContent } from '@/components/markdown-content';
import { MENU_HEIGHT } from '@/constants';
import { MainColumn } from '@/components/main-column';

export type Props = {
  strapiSection: StrapiCareersSection1;
};

export const Section1 = ({ strapiSection }: Props) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={{ position: 'relative', mt: `${MENU_HEIGHT}px` }}
  >
    <Box
      position={'absolute'}
      sx={{
        width: '100%',
        objectFit: 'cover',
        height: '100vh',
        background:
          'linear-gradient(114.91deg, #031520 18.09%, rgba(3, 21, 32, 0.3) 100%)',
      }}
    />
    {/*
      Did not update this one as I couldn't replicate
      the same effect as with the Box
      Remove comment after this box gets replaced
    */}
    {strapiSection?.image?.data?.attributes?.url && (
      <Box
        component={'img'}
        src={strapiSection.image.data.attributes.url}
        width={'100%'}
        alt={strapiSection.image.data.attributes.alternativeText}
        sx={{
          objectFit: 'cover',
          height: '100vh',
        }}
      />
    )}
    <MainColumn>
      <MarkdownContent
        content={strapiSection?.paragraph}
        width={{ md: '700px', xs: 'auto' }}
        mx={{ sm: 'auto', md: 0 }}
        sx={{
          color: 'greyscale.white',
          mt: { md: '219px', sm: 0 },
          position: 'absolute',
          top: { xs: '50%', md: 0 },
          '> *': { color: 'white !important' },
          transform: { xs: 'translateY(-50%)', md: 'none' },
        }}
      />
    </MainColumn>
  </Container>
);
