import { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { StrapiCareersSection2 } from '@/api/careers';
import { MarkdownContent } from '@/components/markdown-content';
import { MainColumn } from '@/components/main-column';
import { TrackedButton } from '@/components/tracked-button';
import axios from 'axios';
import groupBy from 'lodash.groupby';

export type Props = {
  strapiSection: StrapiCareersSection2;
};

export const OpenJobPositions = ({ strapiSection }: Props) => {
  const [selectedTab, setSelectedTab] = useState(
    strapiSection?.tabs[0] ? strapiSection?.tabs[0].text : '',
  );

  const [cityToJobs, setCityToJobs] = useState<
  Record<string, { url: string; title: string }[]>
  >({});

  useEffect(() => {
    axios
      .get('https://apply.workable.com/api/v1/widget/accounts/treeline')
      .then((response) => {
        const map = groupBy(response.data?.jobs || [], 'city');
        setCityToJobs(map);
      });
  }, []);

  return (
    <MainColumn disableGutters maxWidth={false}>
      {strapiSection?.title && (
        <Typography my={{ sm: 10, xs: 5 }} variant={'h2'}>
          {strapiSection.title}
        </Typography>
      )}
      {strapiSection?.tabs?.length && (
        <Grid
          container
          mb={cityToJobs[selectedTab] ? { sm: 7 } : 0}
          sx={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'greyscale.beau',
          }}
        >
          <Tabs value={selectedTab} variant="scrollable">
            {strapiSection.tabs.map(({ text: city }, idx) => (
              <Tab
                sx={{
                  px: '4px',
                  py: 2,
                  mr: 2,
                  ...(selectedTab === city && { fontWeight: 'medium' }),
                }}
                onClick={() => {
                  setSelectedTab(city);
                }}
                key={`${city}${idx}`}
                value={city}
                label={
                  <Typography variant={'body1'}>
                    {' '}
                    {`${city} Office`}{' '}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Grid>
      )}
      <Grid container>
        {cityToJobs[selectedTab]?.map((job, idx) => (
          <Grid
            key={`${job}${idx}`}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              py: 4,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: 'greyscale.steel',
            }}
          >
            <Grid item>
              <Typography variant="h4" color="greyscale.persian">
                {job.title}
              </Typography>
            </Grid>
            <Grid item>
              <TrackedButton
                sx={{ mt: { xs: 3, sm: 0 } }}
                variant="outlined"
                href={job.url}
              >
                {strapiSection?.applyButtonText}
              </TrackedButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {(!cityToJobs[selectedTab] || strapiSection?.tabs?.length === 0) && (
        <Grid
          item
          xs={12}
          sx={{ position: 'relative', height: { xs: '250px', md: '400px' } }}
        >
          <Box
            textAlign={'center'}
            margin={'auto'}
            position={'absolute'}
            sx={{
              bottom: 0,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            bottom={0}
            left={0}
            right={0}
          >
            <Box
              position={'absolute'}
              sx={{
                bottom: 0,
                left: 0,
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <MarkdownContent content={strapiSection?.noHiringParagraph} />
              <TrackedButton
                href={`mailto:${strapiSection?.noHiringButton?.link}`}
                variant="contained"
              >
                {strapiSection?.noHiringButton?.text}
              </TrackedButton>
            </Box>
          </Box>
        </Grid>
      )}
    </MainColumn>
  );
};
