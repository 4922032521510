import { StrapiCareersSection2 } from '@/api/careers';
import { OpenJobPositions } from '@/components/open-job-positions';
import React from 'react';

export type Props = {
  strapiSection: StrapiCareersSection2;
};

export const Section2 = ({ strapiSection }: Props) => (
  <OpenJobPositions strapiSection={strapiSection} />
);
