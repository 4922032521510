import { Container } from '@mui/material';
import React, { ReactNode } from 'react';
import { MENU_HEIGHT } from '@/constants';

export type Props = {
  children: ReactNode;
  menuMargin?: boolean;
};

export const Main = ({ children, menuMargin = false }: Props) => (
  <Container
    sx={{
      mt: menuMargin ? `${MENU_HEIGHT}px` : 0,
    }}
    maxWidth="lg"
    disableGutters
  >
    {children}
  </Container>
);
