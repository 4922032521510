import React from 'react';
import { StrapiCareersSection4 } from '@/api/careers';
import { Box, Grid, Typography } from '@mui/material';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { GatsbyImage } from 'gatsby-plugin-image';

export type Props = {
  strapiSection: StrapiCareersSection4;
};

export const Section4 = ({ strapiSection }: Props) => (
  <MainColumn maxWidth={false} disableGutters>
    {strapiSection?.title && (
      <Typography variant={'h2'}>{strapiSection.title}</Typography>
    )}
    <Grid
      container
      justifyContent={'space-between'}
      sx={{ py: { md: 4, sm: 0 } }}
    >
      {strapiSection?.benefits?.map(({ title, description, image }, index) => {
        const imageData = getGatsbyImageData(image?.data);
        return (
          <Grid item key={index} md={5.5} xs={12} sx={{ pt: { md: 6, xs: 5 } }}>
            <Box
              sx={{
                typography: 'body2',
              }}
            >
              {imageData && (
                <GatsbyImage
                  image={imageData}
                  alt={image.data.attributes.alternativeText || ''}
                />
              )}
              <Typography
                sx={{
                  my: 2,
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
              <Typography variant="body2">{description}</Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  </MainColumn>
);
