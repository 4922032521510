import { styled } from '@mui/system';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledImage3 = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
  },
}));
