import { StrapiCareersSection3 } from '@/api/careers';
import { Container, Grid } from '@mui/material';
import React from 'react';
import { MarkdownContent } from '@/components/markdown-content';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImage3 } from '@/components/sections/careers/Careers.style';

export type Props = {
  strapiSection: StrapiCareersSection3;
};

export const Section3 = ({ strapiSection }: Props) => {
  const imageData = getGatsbyImageData(strapiSection?.image?.data);
  return (
    <MainColumn sx={{ my: { md: 10, xs: 8 } }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item sm={12} md={5.5}>
          <Container
            maxWidth={false}
            disableGutters
            sx={{ position: 'relative' }}
          >
            <MarkdownContent content={strapiSection?.paragraph} />
          </Container>
        </Grid>
        <Grid item sm={12} md={5.5}>
          {imageData && (
            <StyledImage3
              image={imageData}
              alt={strapiSection?.image?.data?.attributes?.alternativeText || 'Career image'}
            />
          )}
        </Grid>
      </Grid>
    </MainColumn>
  );
};
