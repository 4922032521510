import React from 'react';
import { StrapiFooter } from '@/api/footer';
import { StrapiMenu } from '@/api/menu';
import { Menu } from '@/components/menu';
import { Footer } from '@/components/footer';
import { Main } from '@/components/main';
import { Head as HeadComponent } from '@/components/head';
import { StrapiCareers } from '@/api/careers';
import { Section1 } from '@/components/sections/careers/Section1';
import { Section2 } from '@/components/sections/careers/Section2';
import { Section3 } from '@/components/sections/careers/Section3';
import { Section4 } from '@/components/sections/careers/Section4';

export type Props = {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiCareers: StrapiCareers;
  };
};

export default function Careers({
  pageContext: {
    strapiFooter,
    strapiMenu,
    strapiCareers: {
      metadata,
      section1,
      section2,
      section3,
      section4,
    },
  },
}: Props) {

  const headMeta = {
    title: 'Careers at Treeline Interactive',
    meta: {
      title: 'Careers at Treeline Interactive',
      description: 'Treeline works with talented designers, programmers and project managers.',
      keywords: 'Treeline, Interactive, Online, Marketing, Web, Development, SEO, iPhone, React, React Native, JavaScript, TypeScript, node, NestJS, multimedia, video, advertising, Treeline Interactive, San Diego, California, 92019, Jake Fields, Mission Beach, interactive agency, web design, web development, interactive media, android, mobile, iPhone Development, Application Development, iPad development, App development, Mobile App development, Medical App, ski resort App development, resort applications, Mission Beach Application company, San Diego Application development, California Application development, Application Development company, Application Development careers, Application designers, Android Development, Android App Development, Android marketplace, iTunes App Store, Android application development',
    },
  };

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} whiteBackground />
      <Section1 strapiSection={section1} />
      <Main>
        <Section2 strapiSection={section2} />
        <Section3 strapiSection={section3} />
        <Section4 strapiSection={section4} />
      </Main>
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
